import { Auth0Provider } from '@auth0/auth0-react';
import { DeepUpThemeProvider } from '@deepup/mui-theme-deepup';
import { CssBaseline } from '@mui/material';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth } from '@components/Auth';
import { MessageBox } from '@components/MessageBox';
import { useEnvironment } from '@hooks/useEnvironment';

import { AppRoutes } from './AppRoutes';
import { Deprecated } from './Deprecated/Deprecated';
import { Layout } from './Layout';

export const App: React.FC = () => {
  const { auth0Audience, auth0ClientId, auth0Domain, isDeprecated } = useEnvironment();

  return (
    <DeepUpThemeProvider>
      <CssBaseline />
      <Auth0Provider
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: auth0Audience,
        }}
        clientId={auth0ClientId}
        domain={auth0Domain}
      >
        <Auth>
          <BrowserRouter>
            <Layout>{isDeprecated ? <Deprecated /> : <AppRoutes />}</Layout>
          </BrowserRouter>
        </Auth>
      </Auth0Provider>
      <MessageBox />
    </DeepUpThemeProvider>
  );
};
